import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import PropTypes from 'prop-types'
import { Link, useIntl } from 'gatsby-plugin-intl'

const NotFoundPage = props => {
  const intl = useIntl()

  return (
    <Layout location={props.location}>
      <Seo title={intl.formatMessage({ id: 'pages.404.title' })} />
      <div className='with-overlay' style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1506830392367-16cbcd8b007c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80")' }}>
        <div className='overlay' />
        <div className='content'>
          <div className="container py-5 mh80vh d-flex align-items-center justify-content-center">
            <h1 className="big-404">404</h1>
          </div>
        </div>
      </div>
      <div className='bg-black text-center py-5 text-white'>
        <p className="h1 mb-5">{intl.formatMessage({id: 'pages.404.page-not-found'})}</p>
        <Link to={'/'} className="btn btn-outline-light">{intl.formatMessage({id: 'pages.404.go-to-home'})}</Link>
      </div>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
}

export default NotFoundPage
